<template lang="pug">
.component-gallery
  .takeout
    RadioInput(
      v-model="takeout"
      name="takeout"
      label="Retirar na loja"
      value="true"
    )

    RadioInput(
      v-model="takeout"
      name="takeout"
      label="Entregar em casa"
      value="false"
    )

  TextInput(
    v-model="state.postalCode"
    label="Código postal"
    :mask="maskPostalCode"
    @blur="v$.postalCode.$touch"
    :errors="v$.postalCode.$errors"
  )

  TextInput(
    v-model="state.postalCode"
    label="CEP"
    :mask="maskPostalCode"
    @blur="v$.postalCode.$touch"
    :errors="v$.postalCode.$errors"
  )

  TextInput(
    v-model="state.postalCode"
    label="CEP"
    :mask="maskPostalCode"
    @blur="v$.postalCode.$touch"
    :errors="v$.postalCode.$errors"
  )

  SelectInput(
    v-model="state.takeoutStore"
    label="Selecione a loja"
    @blur="v$.takeoutStore.$touch"
    :errors="v$.takeoutStore.$errors"
  )
    option(value="", label="")
    option(v-for="store in stores", :value="store.id", :label="store.name")

  TextAreaInput(
    v-model="state.shippingAddress"
    label="Endereço de entrega"
    @blur="v$.shippingAddress.$touch"
    :errors="v$.shippingAddress.$errors"
    rows="5"
  )

  Rating(
    v-model="state.rating"
  )

  Progress(
    :progress="0.8"
  )

  .actions
    Button(
      label="Calcular frete"
      :icon="['fa', 'cart-shopping']"
      type="primary"
      @click="calculateShipping"
    )

    IconButton(
      to="/"
      :icon="['fa', 'cart-shopping']"
      type="primary"
      @click="calculateShipping"
    )

  Loading
</template>
<script setup lang="ts">
import { maskPostalCode } from '~/scripts/masks/masks'
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '~/scripts/validators/validators'

const state = reactive({
  postalCode: '',
  shippingAddress: '',
  takeoutStore: null,
  rating: 1
})

const rules = {
  postalCode: {
    required,
    minLength: minLength(9),
    maxLength: maxLength(9)
  },
  takeoutStore: { required },
  shippingAddress: { required }
}

const notifications = useNotifications()
const v$ = useVuelidate(rules, state)

const takeout = ref('false')

const takeoutStore = ref(null)
const shippingAddress = ref('')

const stores = ref([{
  id: 1,
  name: 'Loja 1'
}, {
  id: 2,
  name: 'Loja 2'
}])

const calculateShipping = async () => {
  await v$.value.$validate()
}
</script>